import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import QUERIES from '../../../styles/queries';
import { FONTS } from '../../../styles/fonts';

const styles = StyleSheet.create({
  root: {
    background: COLORS.kgGreen,
    [QUERIES.desktop]: {
      paddingTop: 64
    }
  },
  errorText: {
    color: COLORS.hotPink,
    fontWeight: 'bold',
    margin: 0,
    padding: 2,
    paddingLeft: 16,
    fontSize: 14
  },
  successText: {
    color: COLORS.kgDarkGreen,
    fontWeight: 'bold',
    margin: 0,
    padding: 2,
    paddingLeft: 16,
    fontSize: 14
  },
  content: {
    // maxWidth: 1200,
    margin: '0 auto',
    paddingTop: 32,
    [QUERIES.desktop]: {
      paddingTop: 32
    }
  },
  shpockLogoContainer: {
    textAlign: 'center',
    margin: '0 auto',
    [QUERIES.mobile]: {
      paddingBottom: 8
    }
  },
  shpockLogo: {
    width: 120,
    height: 120
  },
  kiffgoLogoContainer: {
    textAlign: 'left',
    flex: 1,
    paddingLeft: 4
  },
  kiffgoLogo: {
    height: 24,
  },
  title: {
    textAlign: 'center',
    padding: 32,
    paddingBottom: 16,
    fontSize: 18,
    lineHeight: '24px',
    color: COLORS.offBlack,
    [QUERIES.mobile]: {
      marginTop: 0
    }
  },
  description: {
    margin: 0,
    padding: 16,
    lineHeight: '32px',
    paddingTop: 0,
    textAlign: 'center',
    color: COLORS.white
  },
  bookOnlineButtonContainer: {
    textAlign: 'center',
    paddingBottom: 16,
    flex: 1,
    minWidth: 200,
  },
  bookOnlineButton: {
    fontFamily: FONTS.circularBold,
    textTransform: 'none',
    padding: 18,
    width: 200,
    height: 64,
    background: COLORS.black,
    position: 'relative'
  },
  newText: {
    paddingLeft: 3,
    paddingRight: 3,
    borderRadius: 4,
    background: COLORS.brightYellow,
    color: COLORS.offBlack,
    // position: 'absolute',
    // right: 8,
    fontSize: 9,
    marginLeft: 8
  },
  downloadImagesContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: 16,
    flexWrap: 'wrap',
    width: 1200,
    margin: '0 auto',
    marginTop: 32,
    paddingBottom: 0,
    [QUERIES.lessThan1200]: {
      marginTop: 0,
      width: 200
    }
  },
  downloadImageContainer: {
    textAlign: 'center',
    flex: 1,
    minWidth: 200,
    [QUERIES.mobile]: {
      flex: '0 0 100%'
    }
  },
  downloadImage: {
    flex: 1,
    width: 200,
    marginBottom: 16
  },
  chatButton: {
    padding: 14,
    textTransform: 'none',
    fontSize: 18,
    fontFamily: FONTS.circularBold,
    margin: '32px auto'
  },
  deliveryWithContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 16,
    paddingBottom: 24
  },
  deliveryWith: {
    color: COLORS.white,
    fontSize: 14,
    flex: 1,
    textAlign: 'right',
    paddingRight: 4,
    lineHeight: '24px'
  },
  emailAndNameContainer: {
    background: COLORS.white,
    position: 'relative',
    margin: '0 auto',
    padding: 8,
    borderRadius: 4,
    marginTop: 32,
    display: 'flex',
    flexDirection: 'column',
    zIndex: 2,
    boxShadow: '0 16px 40px rgba(0,0,0,0.12)',
    maxWidth: 800
  },
  sendPricesButton: {
    background: COLORS.brightYellow,
    textTransform: 'none',
    padding: 16,
    marginTop: 16,
    fontFamily: FONTS.circularBold,
    fontSize: 16
  },
  emailInput: {
    padding: 16,
    display: 'flex',
    border: 'none'
  },
  belowHeaderContent: {
    background: COLORS.paper,
    marginTop: -100,
    paddingTop: 100,
    zIndex: -1,
    paddingBottom: 32,
    [QUERIES.desktop]: {
      paddingBottom: 64
    }
  }
});

export default styles;
