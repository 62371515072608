import React from 'react';
import styles from './Shpock.styles';
import { css } from 'aphrodite';
import { KIFFGO } from '../../../library/kiffgo';
import { androidBase64, iosBase64 } from '../../../assets/download/base-64';
import { LINKS } from '../../../library/links';
import Button from '@material-ui/core/Button/Button';

const Shpock = (props) => (
  <div className={css(styles.root)}>
    <div className={css(styles.content)}>
      <div className={css(styles.shpockLogoContainer)}>
        <a href={'https://www.shpock.com/en-gb'} target={'_blank'}>
          <img alt={'Shpock delivery'} src={require('../../../assets/affiliates/shpock-logo.svg')} className={css(styles.shpockLogo)} />
        </a>
      </div>
      <div className={css(styles.deliveryWithContainer)}>
        <div className={css(styles.deliveryWith)}>delivery with</div>
        <div className={css(styles.kiffgoLogoContainer)}>
          <img alt={'JJD'} src={require('../../../assets/images/logo.svg')} className={css(styles.kiffgoLogo)} />
        </div>
      </div>

      <div className={css(styles.emailAndNameContainer)}>
        <input className={css(styles.emailInput)} placeholder={'Email address'} onChange={props.onChangeEmail} value={props.email} />
        {props.error && <p className={css(styles.errorText)}>{props.error}</p>}
        {props.success && <p className={css(styles.successText)}>{props.success}</p>}
        <Button className={css(styles.sendPricesButton)} onClick={props.submit}>Get delivery prices</Button>
      </div>


      <div className={css(styles.belowHeaderContent)}>
        <h1 className={css(styles.title)}>Same day Shpock delivery in under an hour with JJD. Get on-demand movers to pick up your items from Shpock sellers in a few clicks</h1>
        <div className={css(styles.downloadImagesContainer)}>
          <a onClick={props.clickAppStoreLink} className={css(styles.downloadImageContainer)} href={LINKS.iOS}>
            <img alt={'Man and van app'} src={iosBase64} className={css(styles.downloadImage)} />
          </a>
          <a onClick={props.clickPlayStoreLink} className={css(styles.downloadImageContainer)} href={LINKS.Android}>
            <img alt={'Delivery app'} src={androidBase64} className={css(styles.downloadImage)} />
          </a>
          <div className={css(styles.bookOnlineButtonContainer)}>
            <Button
              className={css(styles.bookOnlineButton)}
              variant={'contained'}
              color={'secondary'}
              onClick={props.clickBookOnlineLink}>
              Book Online<span className={css(styles.newText)}>NEW</span>
            </Button>
          </div>
          <div className={css(styles.bookOnlineButtonContainer)}>
            <Button
              className={css(styles.bookOnlineButton)}
              variant={'contained'}
              color={'secondary'}
              href={KIFFGO.phoneNumberHrefLink}
              onClick={props.clickPhoneNumber}>
              {KIFFGO.phoneNumber}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Shpock;
