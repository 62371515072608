import React, { Component } from 'react';
import Shpock from './Shpock';
import EveryReview from '../../reviews/EveryReview/EveryReview';
import DownloadFree from '../../sections/DownloadFree/DownloadFree';
import Credibility from '../../sections/Credibility/Credibility';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { submitUserInfo } from '../../heroes/RemindMeLater/RemindMeLaterActions';
import Utils from '../../../library/utils';
import { navigate } from 'gatsby';

class ShpockContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: null,
      success: null
    }
  }

  componentDidMount() {
    ReactGA.event({
      category: 'Shpock',
      action: 'Entered shpock page',
      label: 'Shpock page mounted'
    });
  }

  clickBookOnlineLink = () => {
    ReactGA.event({
      category: 'Shpock',
      action: 'Book Online from Shpock',
      label: 'Shpock Book Online'
    });
    navigate('/book');
  };

  clickAppStoreLink = () => {
    ReactGA.event({
      category: 'Shpock',
      action: 'Download from Shpock',
      label: 'Shpock Download iOS'
    });
  };

  clickPlayStoreLink = () => {
    ReactGA.event({
      category: 'Shpock',
      action: 'Download from Shpock',
      label: 'Shpock Download Android'
    })
  };

  clickPhoneNumber = () => {
    ReactGA.event({
      category: 'Shpock',
      action: 'Call from Shpock',
      label: 'Shpock phone call'
    })
  };

  onChangeEmail = (e) => {
    this.setState({
      email: e.target.value,
      error: null,
      success: null
    })
  };

  submit = () => {
    if (Utils.isValidEmail(this.state.email)) {
      ReactGA.event({
        category: 'Shpock',
        action: 'Email me prices form submitted',
        label: 'Send delivery prices clicked'
      });
      this.props.submitForm(this.state.email);
      this.setState({
        success: 'Success!'
      })
    } else {
      ReactGA.event({
        category: 'Shpock',
        action: 'Form submitted incorrectly - no email address',
        label: 'Invalid email address entered'
      });
      this.setState({
        error: 'Please enter a valid email address'
      })
    }
  };

  render() {
    return (
      <>
        <Shpock {...this.props} clickBookOnlineLink={this.clickBookOnlineLink}
                clickAppStoreLink={this.clickAppStoreLink} clickPlayStoreLink={this.clickPlayStoreLink}
                clickPhoneNumber={this.clickPhoneNumber} clickChat={this.clickChat}
                email={this.state.email}
                submit={this.submit}
                onChangeEmail={this.onChangeEmail}
                error={this.state.error}
                success={this.state.success}
        />
        <EveryReview/>
        <DownloadFree/>
        <Credibility/>
      </>
    )
  }

  componentWillUnmount() {
    if (window && window.Chatra && typeof window.Chatra.show === 'function') {
      window.Chatra.show();
    }
  }
}

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    submitForm: (email) => dispatch(submitUserInfo('', '', email, ''))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShpockContainer);
