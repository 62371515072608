import React from 'react';
import ShpockContainer from '../containers/affiliates/Shpock/ShpockContainer';
import Layout from '../containers/gatsby/layout';

const ShpockPage = (props) => (
  <Layout location={props.location}>
    <ShpockContainer {...props} />
  </Layout>
);

export default ShpockPage;
